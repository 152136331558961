<template>
  <div class="property-wrap">
    <div v-if="title" class="property-content">
      <span class="title">{{ title }}</span>
    </div>
    <div v-else class="property-content">
      <div class="property-detail">
        <div class="detail-label">
          <img v-if="labelIcon" :src="labelIcon" alt="" />
          <span class="label">{{ label }}</span>
        </div>
        <div class="detail-value">
          <template v-if="!hasValueSlot">
            <img v-if="showValueIcon" :src="valueIcon" alt="" />
            <span class="label">{{ value }}</span>
          </template>
          <slot name="value"></slot>
        </div>
      </div>
      <PrimaryBtn
        class="property-helper"
        :style="{ visibility: helper ? 'visible' : 'hidden' }"
        :img="helper"
        :active="helper ? helperActive : false"
        :disabled="helperTooltip ? true : false"
        :tooltip="helperTooltip"
        @click="$emit('helper')"
      />
    </div>
    <div v-if="error" class="property-error">
      <span>{{ error }}</span>
    </div>
  </div>
</template>

<script>
import PrimaryBtn from '@/components/Base/PrimaryBtn.vue'

export default {
  name: 'PropertyItem',
  components: {
    PrimaryBtn
  },
  props: {
    title: {
      type: String,
      default: null
    },
    labelIcon: {
      // type:,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    valueIcon: {
      // type:,
      default: null
    },
    value: {
      // type: String,
      default: null
    },
    helper: {
      // type: , // icon img
      default: null
    },
    helperActive: {
      type: Boolean,
      default: false
    },
    helperTooltip: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    }
  },
  data() {
    return {}
  },
  computed: {
    showValueIcon() {
      const bIcon = this.valueIcon ? true : false
      const bValue = this.value !== null && this.value !== undefined
      return bIcon && bValue
    },
    hasValueSlot() {
      return this.$slots.value ? true : false
    }
  },
  watch: {},
  methods: {},
  created() {},
  mounted() {
    // console.log(`[PropertyItem.mounted] value`, this.value)
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}
.property-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  width: 100%;
  .property-content {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .title {
      @include font_bold;
      color: $color_FFE99F;
      width: 100%;
    }
    .property-detail {
      display: flex;
      align-items: center;
      flex: 1;
      border-radius: 0.5rem;
      width: calc(100% - px2rem(40)); // 減掉 helper 寬度
      min-height: px2rem(36);

      img {
        width: 1rem;
        height: 1rem;
      }
      .detail-label {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        border: 1px solid $color_9D9D9D;
        border-radius: 0.5rem 0 0 0.5rem;
        width: 40%;
        height: 100%;

        .label {
          margin-left: 0.5rem;
        }
      }
      .detail-value {
        display: flex;
        align-items: center;
        padding: 0 px2rem(20);
        border: 1px solid $color_9D9D9D;
        border-radius: 0 0.5rem 0.5rem 0;
        width: 60%;
        height: 100%;
        background-color: $color_39425D;

        .label {
          word-break: break-all;
        }

        &:has(img) .label {
          margin-left: 0.5rem;
        }
      }
    }
    .property-helper {
      // full width: 36 + 4 = 40
      margin-left: px2rem(4);
    }
  }
  .property-error {
    margin-top: px2rem(2);
    width: 100%;
    color: $color_F94144;
  }
}
</style>
