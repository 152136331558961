<template>
  <el-tooltip
    popper-class="el-tooltip"
    effect="dark"
    v-delTabIndex
    :disabled="!disabled && !tooltip"
    :content="tooltip"
  >
    <button
      class="primary-btn"
      :class="[effect, { active }]"
      :disabled="disabled"
      @click.stop="$emit('click')"
    >
      <img :src="img" />
    </button>
  </el-tooltip>
</template>

<script>
export default {
  name: 'PrimaryBtn',
  props: {
    img: {
      requred: true
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    },
    effect: { // TODO
      type: String,
      default: 'medium' // 'large','medium','small'
    }
  },
}
</script>

<style lang="scss" scoped>
$BtnSize: px2rem(36);
$ImgSize: px2rem(22);
$BtnPad: calc(($BtnSize - $ImgSize) / 2);
* {
  box-sizing: border-box;
  // user-select: none;
}
.primary-btn {
  border-radius: 0.5rem;
  padding: $BtnPad;
  height: $BtnSize;
  width: $BtnSize;
  background-color: $color_FFF_20;

  img {
    height: $ImgSize;
    width: $ImgSize;
  }

  &:hover:not(:disabled) {
    background-color: $color_FFF_50;
  }
  &.active:not(:disabled) {
    background-color: $color_FFF_50;
  }
  &:disabled {
    @include disabled;
    // &:hover {
    //   background-color: unset;
    // }
  }
}
</style>
