<template>
  <div class="tab-list-wrap" :class="[effect]">
    <div class="tab-item-wrap">
      <button
        class="tab-item"
        v-for="(tab, idx) in tabList"
        :key="`${tab.label}_${idx}`"
        :class="{ current: tabIdx === idx }"
        :disabled="disabled || disabledList.includes(idx)"
        @click="$emit('select', idx)"
      >
        <img
          v-if="tab.icon !== undefined"
          class="icon"
          :src="tab.icon"
          alt=""
        />
        <span class="label">{{ tab.label }}</span>
        <span v-if="tab.count !== undefined" class="count">
          {{ tab.count }}
        </span>
      </button>
    </div>
    <!-- <div v-if="effect === 'dark'" class="vertical-divider"></div> -->
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

export default {
  name: 'TabList',
  components: {},
  props: {
    effect: {
      type: String,
      default: 'dark' // 'light'
    },
    tabList: {
      type: Array, // {id: 'xx', icon: 'xxx', label: 'xxx', count: 0}
      default() {
        return []
      }
    },
    tabIdx: {
      type: Number,
      default: 0
    },
    // Note:
    // disabled & disabledList 擇一使用即可
    // disabled: 禁用整個 tablist
    // disabledList: 禁用非選擇的tab
    disabled: {
      type: Boolean,
      default: false
    },
    disabledList: {
      type: Array, // [0, 1] || [1, 2] || [1, 2] => index array
      default() {
        return []
      }
    }
  },
  data() {
    return {
      tab: null,
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$QuatoW: calc(764 / 1230 * 100%);
$DetailW: calc(100% - $QuatoW);
* {
  box-sizing: border-box;
  // user-select: none;
}
// .vertical-divider {
//   @include vertical_divider;
// }
.tab-list-wrap {
  display: flex;
  flex-direction: column;
  padding-top: px2rem(12);
  color: $color_FFF;

  .tab-item-wrap {
    display: flex;
    height: px2rem(36);
    .tab-item {
      display: flex;
      align-items: center;
      margin-right: px2rem(4);
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      padding: 0 1rem;
      height: 100%;
      // background-color: #f00;
      &.current {
        background-color: $color_39425D;
        cursor: unset;
      }
      &:last-child {
        margin-right: unset;
      }

      &:disabled {
        @include disabled;
      }

      .icon {
        margin-right: 0.5rem;
        width: 1rem;
        height: 1rem;
      }
      .count {
        margin-left: 0.5rem;
        border-radius: 0.5rem;
        padding: 0 px2rem(10);
        font-size: 0.75rem; // 12px
        line-height: 1rem;
        background-color: $color_6E7D93;
      }
    }
  }

  &.light {
    // background-color: $color_4A5C78;
    .tab-item-wrap {
      .tab-item {
        background-color: $color_39425D;
        &.current {
          background-color: $color_282942;
        }
      }
    }
  }
}
</style>
