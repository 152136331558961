<template>
  <el-tooltip
    popper-class="el-tooltip"
    effect="dark"
    v-delTabIndex
    :disabled="!disabled && !tooltip"
    :content="tooltip"
  >
    <button
      class="text-btn"
      :class="{ active }"
      :disabled="disabled"
      @click.stop="$emit('click')"
    >
      <img v-if="img" :src="img" />
      <span :class="{ 'no-img': !img }">{{ label }}</span>
    </button>
  </el-tooltip>
</template>

<script>
export default {
  name: 'SmallTextBtn',
  props: {
    img: {
      // type: true
      default: null
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      required: true,
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    }
  },
}
</script>

<style lang="scss" scoped>
$BtnSize: px2rem(36);
$ImgSize: px2rem(22);
$BtnPad: calc(($BtnSize - $ImgSize) / 2);
* {
  box-sizing: border-box;
}
.text-btn {
  border-radius: 0.5rem;
  padding: 0 1rem;
  height: $BtnSize;
  background-color: $color_4A5C78;

  img {
    height: $ImgSize;
    width: $ImgSize;
  }
  &:not(:disabled):hover {
    background-color: $color_6E7D93;
  }
  &.active {
    background-color: $color_5A6B87;
    &:hover {
      background-color: $color_5A6B87;
    }
  }
  &:disabled {
    @include disabled;
  }
  span {
    margin-left: 0.5rem;
    &.no-img {
      margin-left: unset;
    }
  }
}
</style>
