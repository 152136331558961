<template>
  <div class="property-wrap">
    <div v-if="title" class="property-content">
      <span class="title">{{ title }}</span>
    </div>
    <div v-if="hint" class="property-hint">
      <span class="hint">{{ hint }}</span>
      <el-tooltip v-if="hintTooltiop" popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="hintTooltiop">
        <img class="hint-icon" src="@/assets/icons/info.svg" alt="" />
      </el-tooltip>
    </div>
    <div v-if="!title" class="property-content">
      <div class="property-detail">
        <div class="detail-label">
          <img v-if="labelIcon" :src="labelIcon" alt="" />
          <span class="label">{{ label }}</span>
          <span v-if="required" class="required">*</span>
        </div>
        <div class="detail-value">
          <slot name="property-value"></slot>
          <!-- <div v-if="valueType === 'text'" class="text-value">
            <input
              type="text"
              :value="value"
              :maxlength="valueLimit.max"
              @input="onInputText"
            />
            <span
              class="len"
              :class="{ warn: vertifyStrLen(value, valueLimit.max) }"
              >{{ `${!value ? 0 : value.length}/${valueLimit.max}` }}</span
            >
          </div>
          <SelectTree
            v-else-if="valueType === 'tree'"
            class="tree-value"
            v-model="treeValue"
            :options="treeOptions"
            :placeholder="$t('am_parent_group_hint')"
          />
          <div v-else>Not support the data type: {{ valueType }}</div> -->
        </div>
      </div>
      <SmallBtn
        class="property-helper"
        :style="{ visibility: helper ? 'visible' : 'hidden' }"
        :img="helper"
        :active="helper ? helperActive : false"
        @click="$emit('helper')"
      />
    </div>
    <div v-if="error" class="property-error">
      <span>{{ error }}</span>
    </div>
  </div>
</template>

<script>
import SmallBtn from '@/components/Base/SmallBtn.vue'
// import SelectTree from '@/components/AccountManagement/tools/SelectTree2.vue'

export default {
  name: 'PropertyItemEdit',
  components: { SmallBtn /*, SelectTree*/ },
  props: {
    title: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: null
    },
    hintTooltiop: {
      type: String,
      default: null
    },
    labelIcon: {
      // type:,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    valueIcon: {
      // type:,
      default: null
    },
    value: {
      // type: String,
      default: null
    },
    // valueType: {
    //   type: String,
    //   default: 'text' // 'text', 'tree', 'dropdown'
    // },
    // valueLimit: {
    //   type: Object, // 搭配 valueType = 'text' {min, max, limit}
    //   default: null
    // },
    // treeOptions: {
    //   type: Array,
    //   default() {
    //     return []
    //   }
    // },
    required: {
      type: Boolean,
      default: false
    },
    helper: {
      // type: , // icon img
      default: null
    },
    helperActive: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    }
  },
  data() {
    return {}
  },
  computed: {
    treeValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  watch: {},
  methods: {
    vertifyStrLen(str, len) {
      return str && str.length > 0 && str.length === len
    },
    onInputText($event) {
      const value = $event.target.value
      this.$emit('input', value)
    },
  },
  created() {},
  mounted() {
    // console.log(`[PropertyItemEdit.mounted] treeOptions`, this.treeOptions)
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.property-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  width: 100%;
  .property-content {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .title {
      @include font_bold;
      color: $color_FFE99F;
      width: 100%;
    }
    .property-detail {
      display: flex;
      align-items: center;
      flex: 1;
      border-radius: 0.5rem;
      width: calc(100% - px2rem(40)); // 減掉 helper 寬度
      min-height: px2rem(36);

      img {
        width: 1rem;
        height: 1rem;
        @include filter_FFF;
      }
      .detail-label {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        border: 1px solid $color_9D9D9D;
        border-radius: 0.5rem 0 0 0.5rem;
        width: 40%;
        height: 100%;

        .label {
          margin-left: 0.5rem;
        }
        .required {
          @include required;
        }
      }
      .detail-value {
        display: flex;
        align-items: center;
        // padding: 0 px2rem(20);
        border: 1px solid $color_9D9D9D;
        border-radius: 0 0.5rem 0.5rem 0;
        width: 60%;
        height: 100%;
        background-color: $color_FFF;
        color: $color_black;

        &:has(img) .label {
          margin-left: 0.5rem;
        }

        .text-value {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;

          input[type='text'] {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            padding-left: px2rem(16);
            padding-right: px2rem(16 + 42);
            width: 100%;
            min-height: 100%;
            font-size: 1rem;
          }
          .len {
            position: absolute;
            right: 0.75rem;
            font-size: 0.875rem; // 14px
            color: $color_placeholder;
            &.warn {
              color: $color_F94144;
            }
          }
        }

        .tree-value {}
      }
    }
    .property-helper {
      // full width: 36 + 4 = 40
      margin-left: px2rem(4);
    }
  }

  .property-hint {
    // background-color: #f00;
  }
  .property-error {
    // margin-top: px2rem(2);
    width: 100%;
    font-size: 0.875rem; // 14px
    color: $color_F94144;
  }
}
</style>
